import React from "react";
import { useEffect } from "react";
import { useState } from "react";
//import "react-image-gallery/styles/css/image-gallery.css";

import ImageGallery from 'react-image-gallery'
import CardAgente from "../components/cardAgente";
import { useParams } from "react-router-dom";
import { getAnnuncioData } from "../data/sito";

import { BiFullscreen, BiMap } from "react-icons/bi";
import { FaBath, FaRulerCombined } from "react-icons/fa";
import { MdOutlineBathtub, MdOutlineKingBed } from "react-icons/md";
import { AiOutlineCalendar } from "react-icons/ai";
import { Helmet } from "react-helmet";


const Annuncio = () => {
    const { id } = useParams();
    const [data, setData] = useState({});
    const [loaded, setLoading] = useState(false);
    
    
    useEffect(()=>{
        getAnnuncioData(id).then(e => setData(e)).finally(() => setLoading(true))
    },[])

    console.log(data)
    if (loaded)
    return(
        <>
        <Helmet>
            <title>{"AIR IMMOBILIARE " + data.annuncio.tipologia}</title>
            <meta property="og:title" content={data.annuncio.comune + " - " + data.annuncio.tipologia}/>
            <meta
                name="description"
                content={data.annuncio.annuncio}
            />
            <meta property="og:description" content="IMMOBILE PER INVESTITORI DEL SETTORE CASA VACANZA - RICETTIVO, NELLA VENDITA E' COMPRESO UN POZZO AR..."/>
            <meta property="og:image" content={"https://api.airimmobiliare.it/static/immobili/"+data.annuncio.idImmobile+"/img/"+data.annuncio.thumbnail}/>
            <meta property="og:type" content="airimmobiliare_it:ad"/>
            <meta property="og:url" content={"https://airimmobiliare.it/annunci/"+data.annuncio.codice}/>
        </Helmet>
            <section className="py-5 text-center hbanner mb-5">
                <div className="container">
                    <header className="d-flex flex-wrap align-items-center justify-content-center justify-content-md-between">
                        <a href="/" className="d-flex align-items-center col-md-3 mb-2 mb-md-0 text-dark text-decoration-none">
                            <h6><b>AIR IMMOBILIARE</b></h6>
                        </a>

                        <ul className="nav col-12 col-md-auto mb-2 justify-content-center mb-md-0">
                            <li><a href="/" className="nav-link px-2 link-dark">Home</a></li>
                            <li><a href="/ricerca" className="nav-link px-2 link-dark active">Immobili</a></li>
                            <li><a href="/#agenti" className="nav-link px-2 link-dark">Agenti</a></li>
                            <li><a href="/about" className="nav-link px-2 link-dark">Chi siamo</a></li>
                        </ul>

                        <div className="col-md-3 text-end">
                            <a className="btn me-2 grey" href="/login">Accedi</a>
                        </div>
                    </header>
                    
                </div>
            
            </section>

            <div className="container">
                <div className="row">
                    <div className="col-md-4">
                        <h3>{data.annuncio.tipologia}</h3>
                        <h6>{data.annuncio.comune} - {data.annuncio.zona}</h6>
                    </div>
                    <div className="col-md-4 offset-md-4">
                        <h3>{data.annuncio.prezzo.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.")} €</h3>
                    </div>
                </div>
                <div className="row">
                    {<ImageGallery items={data.immagini}/>}
                </div>
                <div className="row">
                    <div className="col-12 col-sm-12 col-md-9 mt-3">
                        <div className="card">
                            <div className="card-body">
                                <h3>Caratteristiche immobile</h3>
                                <hr/>
                                <div className="container">
                                    {<div className="row row-cols-2 row-cols-lg-3 g-2 g-lg-3">
                                        <div className="col">
                                        <div className="p-3">Tipologia: {data.annuncio.tipologia}</div>
                                        </div>
                                        <div className="col">
                                        <div className="p-3">Letti <MdOutlineKingBed className="icons"/> : {data.annuncio.letti}</div>
                                        </div>
                                        <div className="col">
                                        <div className="p-3">Bagni <MdOutlineBathtub className="icons"/> : {data.annuncio.bagni}</div>
                                        </div>
                                        {/*<div className="col">
                                            <div className="p-3 border bg-light">POSTO AUTO</div>
                                            </div>*/}
                                        <div className="col">
                                        <div className="p-3">Mq <BiFullscreen className="icons"/> : {data.annuncio.superficie}</div>
                                        </div>
                                        <div className="col">
                                        <div className="p-3">Anno di costruzione <AiOutlineCalendar className="icons" />: {data.annuncio.costruzione}</div>
                                        </div>
                                        <div className="col">
                                        <div className="p-3">CODICE: {data.annuncio.codice}</div>
                                        </div>
                                    </div>}
                                </div>
                            </div>
                        </div>
                        <div className="card mt-5">
                            <div className="card-body">
                                <h3>Descrizione</h3>
                                <hr/>
                                <p>{data.annuncio.annuncio}</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-3 mt-3">
                       <CardAgente agente={data.agente} provincia={data.annuncio.provincia}/>
                    </div>
                </div>
            </div>


        </>
    )
}

export default Annuncio;