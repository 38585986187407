import React from "react"

const AgentCard = (props) => {
    const utente = props.agente
    if (utente)
    return(
        <div className="card text-center">
            <img src={utente.img} alt="profilo" className="rounded mx-auto d-block mt-5 pic"/>
            <div className="card-body">
                <h5 className="card-title">{utente.nome}</h5>
            </div>
            <ul className="list-group list-group-flush">
                <li className="list-group-item">Codice: {utente.codice}</li>
                <li className="list-group-item">Telefono: {utente.telefono} {utente.telefono2}</li>
                <li className="list-group-item">Email: {utente.email}</li>
                <li className="list-group-item">Pec: {utente.pec}</li>
                <li className="list-group-item">P.IVA: {utente.piva}</li>
                <li className="list-group-item">REA: {utente.rea}</li>
                <li className="list-group-item">Iscrizione: {utente.iscrizione}</li>
            </ul>
        </div>
    )
    else
    return(
        <div className="card text-center">
            <img src="..." alt="profilo" className="rounded mx-auto d-block mt-5"/>
            <div className="card-body">
                <h5 className="card-title placeholder-glow"></h5>
            </div>
            <ul className="list-group list-group-flush placeholder-glow">
                <li className="list-group-item placeholder"></li>
                <li className="list-group-item placeholder"></li>
                <li className="list-group-item placeholder"></li>
                <li className="list-group-item placeholder"></li>
                <li className="list-group-item placeholder"></li>
                <li className="list-group-item placeholder"></li>
                <li className="list-group-item placeholder"></li>
            </ul>
        </div>
    )
}

export default AgentCard