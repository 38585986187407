import React from "react";
import Whatsapp from "../../components/Whatsapp";

const agente = JSON.parse(localStorage.getItem("agente"));

const Home = () => {
    return(
    <>
        <header>
            <nav className="navbar navbar-expand-lg navbar-light bg-light">
                <div className="container-fluid">
                    <a className="navbar-brand" href="/admin">Home Finder</a>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                        <li className="nav-item">
                            <a className="nav-link active" aria-current="page" href="/admin">Home</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link " aria-current="page" href="/admin/utenti">Utenti</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link " aria-current="page" href="/admin/immobili">Immobili</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link " aria-current="page" href="/admin/agenti">Agenti</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link " aria-current="page" href="/admin/archivio">Archivio</a>
                        </li>                  
                        </ul>
                        
                        <div className="dropdown">
                            <label className="dropdown-toggle" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                            {agente.nome}
                            </label>
                            <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown">
                            <li><a className="dropdown-item" href={`/admin/agenti/${agente.idAgente}`}>Account</a></li>
                            <li><hr className="dropdown-divider"/></li>
                            <li><a className="dropdown-item" href="/logout">Logout</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </nav>
        </header>
        
        <div className="container text-center">
            <div className="row align-items-start">
                <div className="col-4">
                    TODO LIST
                    <ul className="list-group">
                        <li className="list-group-item d-flex justify-content-between align-items-center">
                            A list item
                            <span className="badge bg-primary rounded-pill">14</span>
                        </li>
                        <li className="list-group-item d-flex justify-content-between align-items-center">
                            A second list item
                            <span className="badge bg-primary rounded-pill">2</span>
                        </li>
                        <li className="list-group-item d-flex justify-content-between align-items-center">
                            A third list item
                            <span className="badge bg-primary rounded-pill">1</span>
                        </li>
                    </ul>
                </div>
                <div className="col-8">
                    
                    {<Whatsapp agente={agente}/>}
                </div>
            </div>
        </div>


      
    </>
    )
}




export default Home;