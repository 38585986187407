const proxy = "https://api.airimmobiliare.it/api"

const headers = {
  'Accept': 'application/json',
  'Content-Type': 'application/json'
};

export async function getHomeData() {
  const response = await fetch(`${proxy}/web/home`, {
      method: 'GET',
      headers: headers
  });
  return response.json();
}

export async function getRicercaData() {
    const response = await fetch(`${proxy}/web/ricerca`, {
        method: 'GET',
        headers: headers
    });
    return response.json();
}

export async function searchData(obj) {
  const response = await fetch(`${proxy}/web/ricerca`, {
    method: 'POST',
    headers: headers,
    body: JSON.stringify(obj)
  });
  return response.json();
}

export async function getAnnuncioData(cod) {
  const response = await fetch(`${proxy}/web/annuncio/${cod}`, {
      method: 'GET',
      headers: headers
  });
  return response.json();
}