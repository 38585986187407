import React from "react";

const About = () => {
    return(
        <>
        <section className="py-5 text-center hbanner banner-mini">
            <div className="container">
                <header className="d-flex flex-wrap align-items-center justify-content-center justify-content-md-between mb-5">
                    <a href="/" className="d-flex align-items-center col-md-3 mb-2 mb-md-0 text-dark text-decoration-none">
                        <h6><b>AIR IMMOBILIARE</b></h6>
                    </a>

                    <ul className="nav col-12 col-md-auto mb-2 justify-content-center mb-md-0">
                        <li><a href="/" className="nav-link px-2 link-dark">Home</a></li>
                        <li><a href="/ricerca" className="nav-link px-2 link-dark ">Immobili</a></li>
                        <li><a href="/#agenti" className="nav-link px-2 link-dark">Agenti</a></li>
                        <li><a href="/about" className="nav-link px-2 link-dark active">Chi siamo</a></li>
                    </ul>

                    <div className="col-md-3 text-end">
                        <a className="btn me-2 grey" href="/login">Accedi</a>
                    </div>
                </header>

                
                
                    
          
                
            </div>
            
        </section>
        <section className="container py-5">
            <div className="col-lg-8 col-md-8 mx-auto p-2">
                <h1 className="fw-light">Chi siamo?</h1>
                <p>
                    <b>AIR Immobiliare</b> è una giovane realtà che nasce come rete di professionisti operanti nel campo immobiliare sul territorio ravennate forlivesi.<br />
                        Il nostro concetto di consulenza immobiliare è quello di mettere a disposizione del cliente un'unica figura di riferimento in grado di poter soddisfare tutte le sue esigenze mettendo a disposizione un tessuto di professionisti che collaborano con la nostra realtà, così da poter accompagnare il cliente in tutte le fasi della compravendita.
                </p>      
               
                <h5 className="fw-light">Dove puoi trovarci?</h5>
                
                <div className="row text-center ">
                    <div className="col">
                        <ul className="list-group border-0">
                            <li className="list-group-item border-0">Ravenna</li>
                            <li className="list-group-item border-0">
                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2850.2021999030126!2d12.175339716357877!3d44.40849637910266!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x477dffa6d7fd9269%3A0xbc6f87d8bf8cbe9!2sAIR%20IMMOBILIARE!5e0!3m2!1sit!2sit!4v1653560046966!5m2!1sit!2sit"  loading="lazy"></iframe>
                            </li>
                            <li className="list-group-item border-0"><a className="text-decoration-none" href={`tel:0544771781`}>Chiama: 0544 771 781</a></li>
                        </ul>
                    </div>
                    
                    <div className="col">
                        <ul className="list-group">
                            <li className="list-group-item border-0">Forlì</li>
                            <li className="list-group-item border-0">
                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d91376.61001062593!2d11.898891694705226!3d44.29927865179122!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x132b5788720e60bb%3A0x7fbe542ffacb39c5!2sAIR%20IMMOBILIARE!5e0!3m2!1sit!2sit!4v1663168697429!5m2!1sit!2sit" loading="lazy"></iframe>
                            </li>
                            <li className="list-group-item border-0"><a className="text-decoration-none" href={`tel:0543423388`}>Chiama: 0543 423 388</a></li>
                        </ul>
                    </div>
                </div>
                
  
            </div>
            
        </section>
        </>
    )
}

export default About;