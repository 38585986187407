import React, { useState, useEffect } from "react";
import CardUtenti from "../../components/cards/Utenti";
import { searchUsers } from "../../data/search";
import { addUser, getUsers } from "../../data/utenti";


const agente = JSON.parse(localStorage.getItem("agente"));


const Utenti = () => {
    const [utenti, setUtenti] = useState([]);
    
    const search = (e) => {
        const obj = {
            nome: document.getElementById("nome").value,
            email: document.getElementById("email").value,
            numero: document.getElementById("numero").value
        }
        if (!(obj.nome === "" && obj.email === "" && obj.numero === "")) {
            searchUsers(obj).then(result => setUtenti(result));
        } else console.log("vuoto")
    }
    //console.log(utenti)

    useEffect(()=> {
        getUsers().then(result => setUtenti(result));
    },[])

    const addUsers = async () => {
        const a = JSON.parse(localStorage.getItem("agente"));
        const u = await addUser({agente: a.idAgente});
        window.location.href = '/admin/utenti/' + u.insertId;
    }

    return(
        <>
            <header>
                <nav className="navbar navbar-expand-lg navbar-light bg-light">
                    <div className="container-fluid">
                        <a className="navbar-brand" href="/admin">Home Finder</a>
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        
                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                            <li className="nav-item">
                                <a className="nav-link active" aria-current="page" href="/admin">Home</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link " aria-current="page" href="/admin/utenti">Utenti</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link " aria-current="page" href="/admin/immobili">Immobili</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link " aria-current="page" href="/admin/agenti">Agenti</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link " aria-current="page" href="/admin/archivio">Archivio</a>
                            </li>                   
                            </ul>
                            
                            <div className="dropdown">
                                <label className="dropdown-toggle" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                {agente.nome}
                                </label>
                                <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown">
                                <li><a className="dropdown-item" href={`/admin/agenti/${agente.idAgente}`}>Account</a></li>
                                <li><hr className="dropdown-divider"/></li>
                                <li><a className="dropdown-item" href="/logout">Logout</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </nav>
            </header>
            <button className="fab" onClick={addUsers}>
                +
            </button>
            <div className="container">
                <div className="row mt-4">
                    <h3>Cerca Utenti</h3>
                    <div className="col-sm-12 col-md-4">
                        <div className="input-group mb-3">
                            <input id="nome" type="text" className="form-control" placeholder="Nome" aria-label="Nome" aria-describedby="basic-addon1" onChange={search}/>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-4">
                        <div className="input-group mb-3">
                            <input id="email" type="text" className="form-control" placeholder="Email" aria-label="Email" aria-describedby="basic-addon1" onChange={search}/>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-4">
                        <div className="input-group mb-3">
                            <input id="numero" type="number" className="form-control" placeholder="Numero" aria-label="Numero" aria-describedby="basic-addon1" onChange={search}/>
                        </div>
                    </div>
                    {/*
                        <div className="col-sm-12 col-md-3">
                        <div className="input-group mb-3">
                        <button type="text" className="btn btn-primary w-100" onClick={() => window.location.href = "/utenti/add" }>Aggiungi</button>
                        </div>
                    </div>
                    */}
                </div>
                <hr />
                {
                     utenti.length == 0 ?
                        <div className="alert alert-danger" role="alert">
                        Nessun risultato
                        </div>
                    : null
                    
                }
                <div className="container mt-4">
                    <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 g-3">
                        {
                            utenti && utenti.map((u, i) => {
                                return(
                                    <div className="col" key={i}>
                                        <CardUtenti utente={u} />
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default Utenti