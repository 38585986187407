import { useState, useEffect } from 'react';
import { BiMap } from 'react-icons/bi';
import { sendWhatsapp } from '../../data/agente';
import { previewImm } from '../../data/immobili';

const CardImmobili = (props) => {
    const [immobile, setImmobile] = useState({});
    const [loading, setLoading] = useState(true);
    useEffect(()=> {
        previewImm(props.immobile).then(imm => setImmobile(imm)).finally(() => setLoading(false))
    },[]);

    const sendMessage =  async () => {
        const obj = {
            numero: props.utente.telefono,
            immobile: immobile.codice,
            agente: props.agente.codice,
        }
        await sendWhatsapp(obj);
    }
    if (!loading)
    return(
        <div className="card">
            { immobile.thumbnail ? <img src={`https://api.airimmobiliare.it/static/immobili/${props.immobile}/img/${immobile.thumbnail}`} className="card-img-top" alt={"preview"} /> : null }
            <div className="card-body">
                <h5 className="card-title">{immobile.tipologia}</h5>
                <p className="card-text fs-6"><BiMap className='text-success' />{immobile.comune != null ? immobile.comune : null} - {immobile.zona != null ? immobile.zona : null}</p>
                { immobile.prezzo ? <h6 className="text-primary">€ {immobile.prezzo}</h6> : null}
            </div>
            <ul className="list-group list-group-flush">
                <li className="list-group-item">Superficie: {immobile.superficie} Bagni: {immobile.bagni} Letti: {immobile.letti} Piano: {immobile.piano}</li>
                <li className="list-group-item">Codice: {immobile.codice}</li>
                <li className="list-group-item">Prezzo: € {immobile.prezzo}</li>
                {   props.utente ? 
                    <li className="list-group-item">
                        <div className='row'>
                            <div className='col'>
                                <button className='btn btn-success w-100' onClick={sendMessage}>Whatsapp</button>
                            </div>
                            <div className='col'>
                                <button className='btn btn-success w-100 disabled'>Mail</button>
                            </div>
                        </div>
                    </li> : null
                }
            </ul>
            <div className="card-footer">
                <a href={`/admin/immobili/${props.immobile}`} className="btn btn-primary w-100">Vai all'immobile</a>
            </div>
        </div>
    )
}

export default CardImmobili