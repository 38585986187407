import React, { useState, useEffect, useCallback } from "react";
import CardImmobili from "../../components/cards/Immobili";
import { searchProperties } from "../../data/search";
import { getProperties } from "../../data/immobili";

const agente = JSON.parse(localStorage.getItem("agente"));


const Immobili = () => {
    const [immobili, setImmobili] = useState([]);
    const [loading, setLoading] = useState(true);

    const search = (e) => {
        setLoading(true)
        const obj = {
            codice: document.getElementById("codice").value,
            via: document.getElementById("via").value,
            proprietario: document.getElementById("proprietario").value,
            archivio: 0
        }
        if (!(obj.codice === "" && obj.via === "" && obj.proprietario === "")) {
            searchProperties(obj).then(result => setImmobili(result)).finally(() => setLoading(false));
        } else console.log("vuoto")
        //console.log(immobili)
    }

    useEffect(()=> {
        getProperties().then(result => setImmobili(result)).finally(()=> setLoading(false));
    },[])
    return(
        <>
            <header>
                <nav className="navbar navbar-expand-lg navbar-light bg-light">
                    <div className="container-fluid">
                        <a className="navbar-brand" href="/admin">Home Finder</a>
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        
                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                            <li className="nav-item">
                                <a className="nav-link active" aria-current="page" href="/admin">Home</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link " aria-current="page" href="/admin/utenti">Utenti</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link " aria-current="page" href="/admin/immobili">Immobili</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link " aria-current="page" href="/admin/agenti">Agenti</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link " aria-current="page" href="/admin/archivio">Archivio</a>
                            </li>                  
                            </ul>
                            
                            <div className="dropdown">
                                <label className="dropdown-toggle" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                {agente.nome}
                                </label>
                                <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown">
                                <li><a className="dropdown-item" href={`/admin/agenti/${agente.idAgente}`}>Account</a></li>
                                <li><hr className="dropdown-divider"/></li>
                                <li><a className="dropdown-item" href="/logout">Logout</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </nav>
            </header>
            <div className="container">
                <div className="row mt-4">
                    <h3>Cerca Immobile</h3>
                    <div className="col-sm-12 col-md-4">
                        <div className="input-group mb-3">
                            <input id="codice" type="text" className="form-control" placeholder="Codice" aria-label="Codice" aria-describedby="basic-addon1" onChange={search}/>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-4">
                        <div className="input-group mb-3">
                            <input id="via" type="text" className="form-control" placeholder="Via" aria-label="Via" aria-describedby="basic-addon1" onChange={search}/>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-4">
                        <div className="input-group mb-3">
                            <input id="proprietario" type="text" className="form-control" placeholder="Proprietario" aria-label="Proprietario" aria-describedby="basic-addon1" onChange={search}/>
                        </div>
                    </div>           
                </div>
                <hr />
                <div className="container mt-4">
                    <div className="row row-cols-1 row-cols-sm-1 row-cols-md-3 row-cols-lg-4 g-3">
                        {
                            !loading && immobili && immobili.map((i,k)=> {
                                return(
                                    <div className="col" key={k}>
                                        <CardImmobili immobile={i.idImmobile}/>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
            
        </>
    )
}

export default Immobili