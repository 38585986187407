import React from "react";

const Termini = () => {
    return(
        <>
        <section className="py-5 text-center hbanner banner-mini">
            <div className="container">
                <header className="d-flex flex-wrap align-items-center justify-content-center justify-content-md-between mb-5">
                    <a href="/" className="d-flex align-items-center col-md-3 mb-2 mb-md-0 text-dark text-decoration-none">
                        <h6><b>AIR IMMOBILIARE</b></h6>
                    </a>

                    <ul className="nav col-12 col-md-auto mb-2 justify-content-center mb-md-0">
                        <li><a href="/" className="nav-link px-2 link-dark">Home</a></li>
                        <li><a href="/ricerca" className="nav-link px-2 link-dark ">Immobili</a></li>
                        <li><a href="/#agenti" className="nav-link px-2 link-dark">Agenti</a></li>
                        <li><a href="/about" className="nav-link px-2 link-dark">Chi siamo</a></li>
                    </ul>

                    <div className="col-md-3 text-end">
                        <a className="btn me-2 grey" href="/login">Accedi</a>
                    </div>
                </header>
            </div>
            
        </section>
        <section className="container py-5">
            <div className="col-lg-8 col-md-8 mx-auto p-2">
                <h1 className="fw-light">Condizioni Generali e Termini del Servizio</h1>
                <div className="row">
                    <p>Il sito web in versione desktop e mobile e applicazioni airimmobiliare.it (di seguito: “Sito e App”) sono di proprietà della rete d'impresa AIR IMMOBILIARE, con sede in Via Aldo Bozzi 69, 48124 Ravenna - P. IVA 02673650392, SDI: SU9YNJA (di seguito: “Titolare”).
                        <br/>
                        L'accesso a Sito e App e le informazioni relative a tutti i servizi comporta il rispetto e l'accettazione di tutte le presenti Condizioni Generali d’Uso (di seguito: “Condizioni”). Per questo motivo, si consiglia di leggere attentamente il contenuto se si desidera accedere e utilizzare le informazioni ai fini dei servizi offerti da Sito e App.
                        <br/>
                    </p>
                    <b>Status di Utente </b>
                    <i>L'utilizzo di qualsiasi servizio offerto da Sito e App (di seguito: “Servizio” o “Servizi”) attribuisce all’utilizzatore lo status di Utente (di seguito: “Utente” o “Utenti”). In genere l’Utente non è tenuto a registrarsi per l'accesso ai servizi offerti da Sito e App, tuttavia per l'utilizzo di alcuni Servizi può essere richiesta la registrazione degli Utenti. Questa registrazione si effettuerà nella forma espressamente segnalata nel relativo Servizio o inviando una mail all'indirizzo info@airimmobiliare.it .</i>
                    <b>Tipologia di Utenti  </b>
                    <i>In relazione all'utilizzo di Sito e App vi sono due tipologie di Utenti: Utenti Semplici ed Utenti Professionali.
                        <ul>
                            <li>
                            Gli Utenti Semplici sono privati (ma anche professionisti che utilizzano Sito e App con obiettivi diversi da quelli degli Utenti Professionali, definiti in seguito) e sono definiti come tali per l’utilizzo del Sito e App con l’obiettivo di ricerca immobili e di ricerca di informazioni. Gli Utenti Semplici possono accedere ad alcuni servizi (es. la ricerca di immobili) in maniera anonima, mentre per altri servizi (es. il salvataggio degli annunci, delle ricerche per ricevere informazione su nuovi annunci in modo automatico) è necessario registrarsi prima dell'uso.
                            </li>
                            <li>
                            Gli Utenti Professionali sono agenti immobiliari, imprese edili, altri professionisti e società operanti nel settore immobiliare che utilizzano Sito e App per fornire servizi agli Utenti Semplici. Gli Utenti Professionali possono accedere ai servizi a loro destinati solo dopo avere contattato il Titolare per chiederne l’abilitazione, legata alla stipula di un contratto di servizio (di seguito “Contratto di Servizio”).
                            </li>
                        </ul>
                    La pubblicazione degli annunci è regolamentata per Utenti Semplici e Utenti Professionali al paragrafo Pubblicazione degli annunci.
                    </i>
                    <b>Minori  </b>
                    <i>L’utilizzo dei Servizi è limitato esclusivamente ai maggiorenni. Per utilizzare il Servizio i minori devono avere precedentemente ottenuto il permesso dei genitori, tutori o rappresentanti legali, che saranno responsabili di tutti gli atti realizzati attraverso Sito e App da parte di minori a loro affidati.</i>

                    <b>Usi Consentiti e Vietati  </b>
                    <i>È vietato svolgere, direttamente o indirettamente, qualsiasi forma di sfruttamento commerciale dei Servizi attraverso l’accesso, l'uso, il trasferimento di informazioni contenute nel Sito e App. La violazione di questo divieto sarà perseguita a norma di legge. L'Utente riconosce e accetta che l'uso del contenuto e/o dei Servizi offerti da Sito e App è a proprio esclusivo rischio e sotto la sua responsabilità.
                        <br/>
                        L'Utente si impegna a utilizzare Sito e App e tutti i contenuti e Servizi in conformità alla legge, al buon costume, ordine pubblico e secondo quanto previsto in queste Condizioni. Allo stesso modo si impegna inoltre a fare un uso appropriatodei Servizi e/o dei contenuti di Sito e App e a non impiegarli per attività illecite, contrarie alla buona fede o delittuose,violare i diritti di terzi e/o regolamenti che violano la proprietà intellettuale, o di qualsiasi altra norma dell’ordinamento giuridico.
                        <br/>
                        In particolare, l'Utente si impegna a non trasmettere, introdurre, distribuire e rendere disponibili a terzi, qualsiasi materiale e informazione (dati, messaggi, immagini, file audio e immagini, fotografie, software, ecc.) che siano in contrasto con la legge, la morale, l'ordine pubblico e le Condizioni. È proibito l'uso di Sito e App per scopi diversi da quelli previsti dal Titolare.
                        <br/>
                        Inoltre, il Titolare si riserva il diritto di non concedere l'accesso a servizi e contenuti di Sito e App ad insindacabile giudizio della sua politica commerciale. Allo stesso modo si riserva il diritto di revocare l'accesso alle sue pagine senza preavviso a qualsiasi Utente che violi le disposizioni delle presenti informazioni legali.
                    </i>
                    <b>Proprietà Intellettuale  </b>
                    <i>Il Titolare è il legittimo proprietario di Sito e App, delle pagine che le stesse comprendono, le informazioni o elementi contenuti nei testi, documenti, fotografie, disegni, grafica, database, software, loghi, marchi, nomi commerciali o altri segni protetti da diritti di proprietà intellettuale o industriale.
                        <br/>        
                        A meno che non sia espressamente autorizzato dal Titolare o da terzi detentori dei corrispondenti diritti, oppure a meno che non sia legalmente autorizzato, l'Utente non può riprodurre, trasformare, modificare, decodificare, distribuire, affittare, mettere a disposizione di chiunque o consentire l'accesso attraverso ogni forma di comunicazione al pubblico di qualsiasi degli elementi menzionati al punto precedente.
                    </i>

                    <b>Garanzie e Responsabilità  </b>
                    <i>Il Titolare non garantisce la continuità di accesso né la corretta visualizzazione, il download o l'utilizzo degli elementi e delle informazioni contenute nelle pagine di Sito e App, che possono essere impediti, ostacolati o interrotti da fattori o circostanze al di fuori del suo controllo.  Il Titolare non è responsabile per le informazioni e altri contenuti integrati in zone o siti di terzi accessibili da Sito e App. Il Titolare non si assume alcuna responsabilità per quanto riguarda le informazioni, contenuti, prodotti e servizi offerti o forniti attraverso Sito e App da terze persone o entità.
                        <br/>
                        Né il Titolare, né i fornitori terzi di informazioni assumono alcuna responsabilità per eventuali danni, perdite, reclami o spese derivanti da: (i) interferenze, interruzioni, errori, omissioni, guasti telefonici, ritardi, blocchi o sconnessioni nel funzionamento del sistema elettronico causati da carenze, errori e sovraccarichi sulle linee e le reti di telecomunicazioni, o per qualsiasi altra causa al di fuori del controllo del Titolare, (ii) l'impiego di qualsiasi programma illegale e/o maligno e con ogni tipo di media, come ad esempio virus o quant’altro, (iii) l'uso improprio o scorretto dei siti del Titolare, (iv) la sicurezza di navigazione o errori causati da un cattivo funzionamento del browser o di versioni non aggiornate dei browser stessi, oppure da hardware obsoleto.
                    </i>
                    <b>Contrattazione con Terze Parti attraverso il Sito  </b>
                    <i>L'Utente riconosce e accetta che qualsiasi relazione contrattuale o extracontrattuale formalizzata con gli inserzionisti agenzie immobiliari, imprese edili, Utenti privati, altri soggetti che pubblicano annunci immobiliari (di seguito: “Inserzionisti”) o terzi contattati attraverso Sito e App, così come la partecipazione a concorsi, promozioni, vendita di beni o servizi saranno effettuati solo ed esclusivamente tra l'Utente e l'Inserzionista e/o terza persona. Di conseguenza, l’Utente accetta che il Titolare non abbia alcuna responsabilità per eventuali danni di qualsiasi natura in relazione con i negoziati, conversazioni e/o obbligazioni contrattuali con gli Inserzionisti o terze persone fisiche o giuridiche contattati attraverso il Sito e App.</i>

                    <b>Consultazione degli Annunci  </b>
                    <i>La ricerca delle informazioni nel Sito e App e la visualizzazione degli annunci è effettuata tramite procedure automatiche, il Titolare declina ogni responsabilità in merito alla qualità dei risultati offerti, al contenuto, all'accuratezza, alla veridicità e alla rispondenza alle regole contenute nelle presenti Condizioni.
                        <br/>
                        L'Utente potrà comunque segnalare eventuali annunci illegali, truffaldini, sospetti o che non rispettino le regole previste da queste Condizioni inviando una segnalazione alla redazione di Sito e App via email all'indirizzo info@airimmobiliare.it
                        <br/>
                        Gli annunci presenti su Sito e App vengono pubblicati da agenzie immobiliari, imprese edili, altri professionisti e Utenti privati. La pubblicazione degli stessi non comporta l'approvazione o l'avallo da parte del Titolare, né implica alcuna forma di garanzia da parte di quest'ultima relativamente al contenuto degli stessi. Il Titolare quindi non è responsabile della veridicità, correttezza, completezza, nonché della normativa in materia di privacy e/o di alcun altro aspetto dei suddetti annunci.
                    </i>

                    <b>Pubblicazione degli Annunci  </b>
                    <i>La pubblicazione degli annunci è regolamentata dalle presenti Condizioni Generali per gli Utenti Semplici, e dal Contratto di Servizio per gli Utenti Professionali.
                        <br/>
                        Gli Utenti hanno facoltà di inserire un annuncio in modalità gratuita (fatta salva la possibilità di acquistare servizi che offrono visibilità addizionale all’annuncio stesso). Gli annunci pubblicati sul Sito e App di Immobiliare.it da parte dagli Utenti Semplici potranno essere visibili anche in altri Siti collegati ad airimmobiliare.it e/o di proprietà della stessa.
                        <br/>
                        La pubblicazione di annunci come Utenti Semplici è espressamente vietata agli Utenti Professionali.
                        <br/>
                        Il Titolare si riserva il diritto di cancellare annunci in via permanente o anche temporanea, a sua sola ed esclusiva discrezione.
                    </i>

                    <b>Privacy e Protezione dei Dati Personali  </b>
                    <i>I dati personali degli Utenti sono trattati in conformità alle norme vigenti. Per ulteriori informazioni, si prega di prendere visione dell'Informativa sulla Privacy e Protezione dei Dati Personali.</i>

                    <b>Sicurezza  </b>
                    <i>Il Titolare ha adottato le misure di sicurezza ritenute idonee a garantire la segretezza e la riservatezza dei dati personali, come definito secondo le norme di legge vigenti. Per ulteriori informazioni, si prega di prendere visione dell’Informativa sulla Privacy e Protezione dei Dati Personali.</i>

                    <b>Cookie  </b>
                    <i>Alcune pagine di Sito e App dispongono di cookies non invasivi o dannosi, né contenenti dati personali: l'unica funzione dei cookies è quella di personalizzare la navigazione. L'Utente può attivare le impostazioni del proprio browser per rifiutare l'installazione di cookies. Per ulteriori informazioni, si prega di prendere visione della pagina Uso dei Cookie.</i>

                    <b>Risoluzione  </b>
                    <i>Il Titolare si riserva il diritto di interdire l'accesso a Sito e App, in via permanente o anche temporanea, a sua sola ed esclusiva discrezione. Gli Utenti destinatari del provvedimento di esclusione saranno informati di tale decisione e non potranno successivamente tentare di accedere al sito sotto diverso nome o a mezzo di altri Utenti.
                        <br/>
                    L'Utente risponderà dei danni di qualsiasi tipo che il Titolare o le sue controllate potrebbero subire, direttamente o indirettamente, come conseguenza di qualsiasi violazione degli obblighi derivati dalle Condizioni di Utilizzo relativamente all’uso del Sito e App. Inoltre, l’Utente manterrà il Titolare indenne da qualsiasi sanzione, reclamo o citazione a giudizio da parte di terzi, compresi Enti pubblici, contro il Titolare, i suoi dipendenti o collaboratori, a seguito della violazione dei diritti di terzi da parte dell'Utente utilizzando il Sito e App o servizi ad esso connessi in modo contrario a quanto previsto dalle presenti Condizioni.</i>

                    <b>Modifiche  </b>
                    <i>Il Titolare si riserva il diritto di apportare le modifiche che ritiene opportune, potendo modificare, cancellare e aggiungere nuovi contenuti e/o servizi così come il modo in cui gli stessi vengono presentati e localizzati.</i>

                    <b>Durata e Termine  </b>
                    <i>I servizi gratuiti e/o i contenuti di Sito e App potranno essere erogati a tempo indeterminato fin quando l’account dell’utente rimarrà attivo; inoltre, ove specificato, i servizi comprendenti Opzioni a Pagamento hanno durata vincolata al periodo di tempo indicato nella pagina del processo di pubblicazione annunci all’interno del Sito e App. Il Titolare ha il potere di chiudere, sospendere o interrompere unilateralmente in qualsiasi momento e senza preavviso, la prestazione del Servizio e del Sito e App.</i>

                    <b>Legge applicabile e Giurisdizione  </b>
                    <i>Salvo quanto disposto da norme di legge non derogabili, il Tribunale di Milano sarà competente in via esclusiva a dirimere ogni controversia riguardante le presenti Condizioni ed i rapporti dalle stesse regolati.</i>


  
  
  
  
  
  
  


                </div>
                
  
            </div>
            
        </section>
        </>
    )
}

export default Termini;