import React from "react";
import { useEffect } from "react";
import { useCallback } from "react";
import { useState } from "react";
import { BiFullscreen, BiMap } from "react-icons/bi";
import { FaBath, FaRulerCombined } from "react-icons/fa";
import { MdOutlineBathtub, MdOutlineKingBed } from "react-icons/md";
import { getHomeData } from "../data/sito";
import cucina from "../img/cucina.jpg"
import { CookieBanner } from "@palmabit/react-cookie-law";


const Home = () => {
    const [data, setData] = useState({});
    const [ricerca, setRicerca] = useState({});
    const [loaded, setLoading] = useState(false);
    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);
    useEffect(()=>{
        getHomeData().then(e => setData(e)).finally(() => setLoading(true))
    },[])

    const selectProvincia = (e) => {
        ricerca.provincia = e.target.value
        setRicerca(ricerca)
        forceUpdate();
    }
    const selectComune = (e) => {
        ricerca.comune = e.target.value
        setRicerca(ricerca)
        forceUpdate();
    }
    const selectZona = (e) => {
        ricerca.zona = e.target.value
        setRicerca(ricerca)
        //forceUpdate();
    }
    const submit = (e) => {
        e.preventDefault();
        const p = ricerca.provincia ? ricerca.provincia : "n"
        const c = ricerca.comune ? ricerca.comune : "n"
        const z = ricerca.zona ? ricerca.zona : "n"
        window.location.href = "/ricerca/"+ p + "-" + c + "-" + z
    }

    console.log(data)
    return(
    <div className="parallax-container">
        <section className="py-5 text-center hbanner">
            <div className="container">
                <header className="d-flex flex-wrap align-items-center justify-content-center justify-content-md-between mb-5">
                    <a href="/" className="d-flex align-items-center col-md-3 mb-2 mb-md-0 text-dark text-decoration-none">
                        <h6><b>AIR IMMOBILIARE</b></h6>
                    </a>

                    <ul className="nav col-12 col-md-auto mb-2 justify-content-center mb-md-0">
                        <li><a href="/" className="nav-link px-2 link-dark active">Home</a></li>
                        <li><a href="/ricerca" className="nav-link px-2 link-dark">Immobili</a></li>
                        <li><a href="#agenti" className="nav-link px-2 link-dark">Agenti</a></li>
                        <li><a href="/about" className="nav-link px-2 link-dark">Chi siamo</a></li>
                    </ul>

                    <div className="col-md-3 text-end">
                        <a className="btn me-2 grey" href="/login">Accedi</a>
                    </div>
                </header>

                
                <h1 className="display-5 fw-bold">AIR IMMOBILIARE</h1>
               
                <div className="col-lg-9 mx-auto">
                    <p className="lead mb-4"><i className="red-text"><b>Le case sono fatte per viverci, non per essere guardate</b></i></p>{/*Le case sono fatte per viverci, non per essere guardate*/}
                    
                    
                    <div className="card mt-5 p-2 text-left card-ricerca">
                        <h5>Cerca la tua casa</h5>
                        <div className="card-body">
                            
                            <form className="container" onSubmit={submit}>
                                <div className="row">
                                    <div className="col-sm-12 col-md-3 mt-2">
                                        <select id="provincia" className="form-select" aria-label="Default select example" onChange={selectProvincia}>
                                            <option>Provincia</option>
                                            <option value="7">Ravenna</option>
                                            <option value="3">Forlì - Cesena</option>
                                        </select>
                                    </div>
                                    <div className="col-sm-12 col-md-3 mt-2">
                                        <select className="form-select" aria-label="Default select example" onChange={selectComune}>
                                            <option>Comune</option>
                                            {
                                                ricerca.provincia && data.comuni && data.comuni.map((c,k) => {
                                                    if (c.provincia.toString() === ricerca.provincia)
                                                        return(
                                                            <option value={c.idComune} key={k}>{c.nome}</option>
                                                        )
                                                })
                                            }
                                        </select>
                                    </div>
                                    <div className="col-sm-12 col-md-3 mt-2">
                                        <select className="form-select" aria-label="Default select example" onChange={selectZona}>
                                            <option>Zone</option>
                                            {
                                                ricerca.comune && data.zone && data.zone.map((z,k) => {
                                                    if (z.comune.toString() === ricerca.comune)
                                                        return(
                                                            <option value={z.idZona} key={k}>{z.nome}</option>
                                                        )
                                                })
                                            }
                                        </select>
                                    </div>
                                    <div className="col-12 col-md-3 mt-2">
                                        <button type="submit" className="btn grey w-100">Cerca</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                <div className="blank-space">

                </div>
                
                
            </div>
            
        </section>

        <section className="px-4 pt-5 my-5 text-center">
            <h1 className="display-6 fw-bold">Annunci</h1>
            <div className="col-lg-6 mx-auto">
                <p className="lead mb-4">Immobili più recenti in vendita e in affitto con noi</p>
            </div>
            <div className="container px-5 mb-5">
                <div className="row row-cols-1 row-cols-md-3 row-cols-lg-4 g-2 g-lg-3">
                    {
                        data.annunci && data.annunci.map((a,k) => {
                            return(
                                <div className="col" key={k}>
                                    <div className="card card-annuncio" onClick={()=> window.location.href = '/annunci/'+a.codice}>
                                        <img src={"https://api.airimmobiliare.it/static/immobili/"+a.idImmobile+"/img/"+a.thumbnail} className="card-img-top" alt="anteprima immobile" />
                                        <div className="card-img-overlay h-100 d-flex justify-content-end">
                                            <p className="card-text price">{a.prezzo.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.")} €</p>
                                        </div>
                                        <div className="card-body">
                                            <h5 className="card-title">{a.tipologia}</h5>
                                            <p className="card-text">Cod: {a.codice}<br/><BiMap className="icons text-verde" />{a.comune} - {a.zona}
                                            <br/><MdOutlineKingBed className="icons"/> {a.letti} <MdOutlineBathtub className="icons"/> {a.bagni} <BiFullscreen className="icons"/> {a.superficie}</p>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            
            </div>
        </section>

        <section>
            <div className="parallax-one text-center">
                <h1 className="display-6 fw-bold text-white">Servizi</h1>
                <div className="container mt-5">
                    <div className="row row-cols-1 row-cols-sm-2 row-cols-md-4 g-1">
                        <div className="col">
                            <div className="p-3 servizio">
                                Visita in comodità
                            </div>
                        </div>
                        <div className="col">
                            <div className="p-3 servizio">
                                Valutazione gratuita
                            </div>
                        </div>
                        <div className="col">
                            <div className="p-3 servizio">
                                Lavora con noi
                            </div>
                        </div>
                        <div className="col">
                            <div className="p-3 servizio">
                                Consulenza mutuo
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </section>

        <section id="agenti" className="px-4 pt-5 my-5 text-center">
            <h1 className="display-6 fw-bold">Agenti</h1>
            <div className="container">
                <div className="row row-cols-2 row-cols-lg-4 g-2 g-lg-3">
                    {
                        data.agenti && data.agenti.map((a,k)=>
                            (
                                <div className="col" key={k}>
                                    <div className="card p-3 card-agente">
                                        <img src={a.img} className="card-img-top img-agente rounded-circle p-4" alt="foto profilo agente" />
                                        <div className="card-body">
                                            <h5 className="card-title green">{a.nome.split(" ")[0]}</h5>
                                            <p className="card-text">{ a.consulente == 0 ? "Agente immobiliare" : "Consulente"} </p>
                                        </div>
                                    </div>
                                </div>
                            )
                        )
                    }
                    
                </div>
            </div>

        </section>

        <section className="p-5">
            <footer className="my-5 pt-5 text-muted text-center text-small">
                <p className="mb-1">© 2020 AIR IMMOBILIARE</p>
                <ul className="list-inline">
                    <li className="list-inline-item"><a href="/privacy">Privacy</a></li>
                    <li className="list-inline-item"><a href="/termini">Terms</a></li>
                </ul>
            </footer>
        </section>

        <CookieBanner
            message="Con il tuo consenso, noi e i nostri partner utilizziamo i cookie e tecnologie simili per archiviare, accedere ed elaborare i dati personali come, ad esempio, la visita al sito Web. In qualsiasi momento, l'utente può ritirare il consenso o rifiutare l'elaborazione dei dati in base al legittimo interesse, facendo clic su 'Ulteriori informazioni' o sulla pagina dell'Informativa sulla privacy presente sul sito."
            className="cookie-banner"
            wholeDomain={true}
            policyLink={'/privacy'}
            privacyPolicyLinkText="Privacy Policy"
            acceptButtonText="Accetto"
            declineButtonText="Rifiuto"
            dismissOnScroll={true}
            onAccept={() => {}}
            onAcceptPreferences={() => {}}
            onAcceptStatistics={() => {}}
        />
    
    </div>)
}

export default Home;