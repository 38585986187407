const proxy = "https://api.airimmobiliare.it/api"
const headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
};

export async function getUsers() {
    const response = await fetch(`${proxy}/utenti`, {
        method: 'GET',
        headers: headers,
    });
    return response.json();
}

export async function getData(id) {
    //console.log(id)
    const response = await fetch(`${proxy}/admin/utenti/${id}`, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
    });
    return response.json();
}
  
export async function addNewUser(obj) {
      const response = await fetch(`${proxy}/utenti`, {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(obj)
      });
      return response.json();
}
  
export async function getDBUsers() {
      const response = await fetch(`${proxy}/utenti`, {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: ""
      });
      return response.json();
}
  
export async function getUser(id) {
  const response = await fetch(`${proxy}/utenti/${id}`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
  });
  return response.json();
}
  
export async function updateUtente(id, agente, utente) {
    const response = await fetch(`${proxy}/utenti/${id}`, {
        method: 'PUT',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({agente: agente, utente: utente})
    });
    return response.json();
}
  
export async function getRichiesta(id) {
    const response = await fetch(`${proxy}/richieste/${id}`, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
    });
    return response.json();
}
  
export async function addRichiesta(idUtente, agente) {
    const response = await fetch(`${proxy}/richieste`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({agente: agente, utente: idUtente})
    });
    return response.json();
}
  
export async function updateRichiesta(id, agente, richiesta) {
    const response = await fetch(`${proxy}/richieste/${id}`, {
        method: 'PUT',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({agente: agente, richiesta: richiesta})
    });
    return response.json();
}

export async function updateRicerca(id, ricerca) {
  const response = await fetch(`${proxy}/richieste/${id}`, {
      method: 'PATCH',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({id: id, key: "ricerca", value: ricerca})
  });
  return response.json();
}
  
export async function getTipologie() {
    const response = await fetch(`${proxy}/api/immobil`);
    return response.json();
}
  
export async function getDimensioni() {
    const response = await fetch(`${proxy}/api/dimensioni`);
    return response.json();
}
  
export async function getAccessori() {
    const response = await fetch(`${proxy}/api/accessori`);
    return response.json();
}
  
  
  
export async function getProperties(utente) {
    const response = await fetch(`${proxy}/api/immobili/from/${utente} `, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: ""
    });
    return response.json();
}
  
export async function getSearch(id, richiesta) {
    //console.log(richiesta)
    const response = await fetch(`${proxy}/api/utente/${id}/proposte`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(richiesta)
    });
    return response.json();
}
  
export async function getDiario(id) {
    const response = await fetch(`${proxy}/api/diario/0/${id}`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: ""
    });
    return response.json();
}


/**/

export async function getDataRichiesta(id) {
  const response = await fetch(`${proxy}/admin/utenti/richieste/${id}`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
  });
  return response.json();
}

export async function addImmobile(id, agente) {
  const response = await fetch(`${proxy}/immobili`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({utente: id, agente: agente})
  });
  return response.json();
}

export async function addUser(obj) {
  const response = await fetch(`${proxy}/utenti`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(obj)
  });
  return response.json();
}