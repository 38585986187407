import React from "react";

const Privacy = () => {
    return(
        <>
        <section className="py-5 text-center hbanner banner-mini">
            <div className="container">
                <header className="d-flex flex-wrap align-items-center justify-content-center justify-content-md-between mb-5">
                    <a href="/" className="d-flex align-items-center col-md-3 mb-2 mb-md-0 text-dark text-decoration-none">
                        <h6><b>AIR IMMOBILIARE</b></h6>
                    </a>

                    <ul className="nav col-12 col-md-auto mb-2 justify-content-center mb-md-0">
                        <li><a href="/" className="nav-link px-2 link-dark">Home</a></li>
                        <li><a href="/ricerca" className="nav-link px-2 link-dark ">Immobili</a></li>
                        <li><a href="/#agenti" className="nav-link px-2 link-dark">Agenti</a></li>
                        <li><a href="/about" className="nav-link px-2 link-dark">Chi siamo</a></li>
                    </ul>

                    <div className="col-md-3 text-end">
                        <a className="btn me-2 grey" href="/login">Accedi</a>
                    </div>
                </header>

                
                
                    
          
                
            </div>
            
        </section>
        <section className="container py-5">
            <div className="col-lg-8 col-md-8 mx-auto p-2">
                <h1 className="fw-light">Privacy</h1>
                <div className="row">
                <p>La presente informativa è resa, nel rispetto degli artt. 13 e 14 del Regolamento UE 679/2016 (di seguito “Regolamento”), agli utilizzatori (di seguito: “Utenti” oppure “Utente”) del sito in versione desktop e mobile e applicazioni airimmobiliare.it, (di seguito: “Sito e App”) di proprietà di AIR IMMOBILIARE, Titolare del Trattamento dei dati personali (di seguito: “Titolare”) ed ha lo scopo di descrivere le modalità di gestione di Sito e App con riferimento al trattamento dei dati personali, nonché di consentire agli Utenti di Sito e App di conoscere le finalità e le modalità di trattamento dei dati personali da parte del Titolare in caso di loro conferimento.</p>

                <p>Come specificato nelle Condizioni Generali e Termini del Servizio i servizi offerti dal Titolare sono rivolti a persone di età superiore ai 18 anni. Qualora il Titolare dovesse venire a conoscenza del trattamento di dati di minori di 18 anni di età senza un valido consenso dei genitori o di un tutore legale, si riserva il diritto di interrompere unilateralmente la fruizione del servizio offerto nonché di cancellare i dati acquisiti.</p>

                <p>I termini che non vengono definiti nella presente Informativa sulla protezione dei dati personali hanno lo stesso significato descritto nelle Condizioni Generali e Termini del Servizio.</p>
                
                <b>Principi applicabili al Trattamento dei Dati Personali  </b>
                <i>Il Titolare, ai sensi e per gli effetti del Regolamento, rende noto che la citata normativa prevede la tutela delle persone fisiche rispetto al trattamento dei dati personali, e che tale trattamento sarà improntato ai principi di correttezza, liceità, trasparenza e di tutela della riservatezza e dei diritti fondamentali.</i>

                <b>Tipologia di utenti  </b>
                <i>In relazione all'utilizzo di Sito e App si distinguono le seguenti tipologie di Utenti:
                <ul>
                    <li>Gli Utenti Semplici possono accedere ad alcuni servizi (es. ricerca di immobili) in maniera anonima, mentre per altri servizi (come pubblicare una inserzione o ricevere aggiornamenti in modo automatico) è necessario registrarsi prima dell'uso.</li>

                    <li>Gli Utenti Professionali, previa sottoscrizione di un Contratto di Servizio, hanno accesso a determinati servizi a loro destinati.</li>
                </ul>
                </i>

                <b>Finalità, base giuridica del Trattamento e Facoltatività del Conferimento  </b>
                <i>I dati personali forniti dagli Utenti attraverso l’utilizzo di Sito e App, verranno trattati con il loro consenso, per le finalità di seguito descritte:

                <ul>
                    <li>
                        A) Erogazione del servizio per Utenti Semplici:
                        <ul>
                            <li>
                            Al fine di erogare alcuni servizi quali ad esempio il salvataggio degli annunci, il salvataggio di una ricerca e la ricezione di informazioni su nuovi annunci in modo automatico, è necessario che l’Utente effettui la Registrazione attraverso il Sito e App. I dati necessari alla Registrazione si limitano al conferimento di:<br/>
                            Nome<br/>
                            Cognome<br/>
                            Indirizzo email<br/>
                            Numero di telefono<br/>
                            </li>
                            <li>
                            L’Utente, accedendo alla propria sezione personale presente su Sito e App (di seguito “Profilo Utente”), potrà fornire ulteriori dati personali a titolo di informazioni aggiuntive (es. data di nascita, sesso, residenza) che verranno raccolti dal Titolare qualora forniti.
                            </li>
                        </ul>
                    </li>
                    <li>
                        B) Erogazione dei servizi di Contatto Inserzionisti e di Inoltro Ricerche:
                        <ul>
                            <li>
                            Affinché gli Inserzionisti possano fornire all’Utente le informazioni che questi richiedono relative agli immobili, il servizio di Contatto Inserzionisti consente all’Utente di inoltrare i propri dati personali ad agenzie immobiliari, imprese edili, altre società e professionisti immobiliari ed utenti privati che pubblicano annunci immobiliari.
                            </li>
                            <li>
                            Al fine di facilitare gli Utenti nella propria ricerca immobiliare, il servizio di Inoltro Ricerche permette di inoltrare le ricerche effettuate dall'Utente alle agenzie immobiliari e altre società e professionisti competenti nella zona di ricerca.
                            </li>
                        </ul>
                    </li>
                    <li>
                        C) Erogazione del servizio di Consulenza Mutui:
                        <ul>
                            <li>
                                Affinché gli Utenti possano ricevere consulenza relativa a finanziamenti da parte di banche o intermediari finanziari, il servizio di Consulenza Mutui permette agli Utenti di inoltrare dati personali e informazioni sugli orientamenti e le preferenze circa l’ubicazione, la tipologia, le caratteristiche degli immobili e l’eventuale finanziamento, di ricevere proposte relative a mutui e finanziamenti.
                            </li>
                        </ul>
                    </li>
                    <li>
                        D) Attività informative, promozionali e di profilazione:
                        <ul>
                            <li>
                            Al fine di consentire all’Utente di ottenere informazioni di settore quali notizie e curiosità, nonché informazioni pubblicitarie, l’Utente può aderire al servizio di Attività informative, promozionali e di profilazione per ricevere comunicazioni informative, promozionali e di marketing sulla base, in alcuni casi, dell’attività effettuata su Sito e App.<br/>
                            È possibile annullare in qualsiasi momento l'iscrizione alle suddette comunicazioni, rispondendo ad i messaggi di comunicazione esprimendo il dissenso. Il Titolare potrà così effettuare attività di analisi degli interessi, abitudini e scelte degli Utenti, anche al fine di poter inviare loro materiale promozionale personalizzato sui servizi offerti.
                            </li>
                        </ul>

                    </li>
                    <li>
                        E) Erogazione del servizio per Utenti Professionali:
                        <ul>
                            <li>
                            Al fine di consentire ad operatori professionali di qualificarsi, previa stipula di un Contratto di Servizi con il Titolare, come Utenti Professionali, è necessario che l’operatore fornisca i dati personali di seguito indicati, per essere ricontattato al fine di conoscere i servizi dedicati ai professionisti:<br/>
                            Nome<br/>
                            Cognome<br/>
                            Ragione sociale<br/>
                            Comune<br/>
                            Indirizzo email<br/>
                            Numero di telefono
                            </li>
                            <li>
                            Il trattamento dei dati per le finalità di cui alle lettere da A ad E, trova la sua base giuridica nell’art. 6 (a) del Regolamento ([…] l’interessato ha espresso il consenso al trattamento dei propri dati personali per una o più specifiche finalità).<br/>
                            </li>
                            <li>
                            Nell’ipotesi di cui alla lettera A (II), il mancato conferimento non comprometterà l’erogazione del servizio ivi previsto.<br/>
                            </li>
                            <li>
                            In particolare, nell’ipotesi della lettera A (I) il mancato conferimento dei dati necessari alla registrazione dell’Utente, determinerà l’impossibilità di erogare anche i servizi di cui alle successive lettere B, C e D.<br/>
                            </li>
                        </ul>
                    </li>
                </ul>
                </i>

                <b>Modalità di Trattamento e Conservazione dei Dati Personali  </b>                      
                <i>Il Titolare assicura che i dati personali sono trattati nel pieno rispetto del Regolamento, mediante sistemi manuali, informatici o telematici. Il trattamento potrà essere effettuato anche attraverso strumenti automatizzati atti a memorizzare, gestire e trasmettere i dati stessi.
                    I dati raccolti ed oggetto di trattamento saranno protetti con metodologie fisiche e logiche tali da ridurre al minimo i rischi di accesso non consentito, diffusione, perdita e distruzione dei dati, ai sensi degli art. 25 e 32 del Regolamento.
                <br/>
                Il trattamento dei dati avrà durata non superiore a quanto necessario per soddisfare le finalità per le quali sono stati raccolti, quali la memorizzazione di criteri di ricerca, la notifica, la pubblicazione di annunci e il contatto di operatori professionali.
                <br/>
                Ai sensi dell’art. 7 comma 3 del Regolamento, l’interessato ha diritto di ottenere in qualsiasi momento la revoca del consenso al trattamento. Per la richiesta di cancellazione dei propri dati personali l'interessato potrà inviare una richiesta all’indirizzo email info@airimmobiliare.it
                <br/>
                Qualora non pervenga al Titolare una richiesta di cancellazione, i dati personali saranno conservati per un termine non superiore a 10 (dieci) anni, con decorrenza dalla data dell’ultimo accesso a Sito e/o App da parte dell’Utente.
                </i>
                <b>Destinatari dei Dati Personali  </b>
                <i>I dati personali raccolti potranno essere trattati da soggetti o categorie di soggetti che agiscono come Responsabili del trattamento dei dati ai sensi dell’art. 28 del Regolamento o che sono autorizzati al trattamento dei dati ai sensi dell’art. 29 del Regolamento.
                <br/>
                Inoltre, per alcuni servizi, i dati potranno essere comunicati a società che collaborano o utilizzano i servizi del Titolare (ad esempio i singoli proponenti per le informazioni sugli immobili; banche o intermediari creditizi per l’erogazione di mutui e prestiti ed altri servizi finanziari connessi alla compravendita di un immobile), con l'unico intento di erogare i servizi richiesti dall'Utente. In questi casi le società sono autonome titolari, pertanto il Titolare non è responsabile del trattamento dei dati da parte delle stesse. Il Titolare inoltre non è responsabile dei contenuti e del rispetto della normativa in tema di protezione dei dati personali da parte di siti non gestiti dal Titolare.
                <br/>
                Al di fuori delle suindicate ipotesi, i dati personali non saranno oggetto di comunicazione se non nei confronti di soggetti, enti e Autorità verso cui la comunicazione sia obbligatoria in forza di disposizioni di legge o di regolamento.
                </i>

                <b>Trasferimento dei Dati ad un Paese Terzo o a un’Organizzazione Internazionale  </b>
                <i>I dati personali raccolti tramite il Sito e App, potranno essere trasferiti al di fuori del territorio nazionale, solo ed esclusivamente per l'esecuzione dei servizi richiesti tramite Sito e App e nel rispetto delle specifiche disposizioni previste dal Regolamento.
                    <br/>
                    Alcuni dati personali potrebbero essere condivisi con destinatari situati al di fuori dello Spazio Economico Europeo. Il Titolare assicura che il trattamento dei dati personali da parte di questi destinatari avviene nel rispetto del Regolamento.
                    <br/>
                </i>

                <b>Raccolta Dati di Navigazione  </b>
                <i>I sistemi informatici e le procedure tecniche e software sottostanti il funzionamento di Sito e App acquisiscono, nel corso del loro normale esercizio, alcuni dati personali la cui trasmissione è implicita nei meccanismi di accesso e funzionamento e dei protocolli in uso su Internet.
                    <br />
                    Ogni volta che l’Utente si collega a Sito e App e ogni volta che richiama o richiede un contenuto i dati d’accesso vengono memorizzati presso i nostri sistemi, sotto forma di file di dati tabellari o lineari.
                    <br/>
                    Rientrano in questa categoria di dati, ad esempio, gli indirizzi IP, i nomi a dominio dei computer utilizzati dagli utenti che si connettono a Sito e App, la richiesta da parte del browser dell’Utente, sotto forma di indirizzi in notazione URI (Uniform Resource Identifier), la data e l’ora della richiesta al server, il metodo utilizzato nel sottoporre la richiesta al server, la quantità di dati trasmessa, il codice numerico indicante lo stato della risposta data dal server ed altri parametri relativi al sistema operativo e all'ambiente informatico dell'Utente.
                    <br />
                    Questi dati potranno essere utilizzati dal Titolare al solo fine di ricavare informazioni statistiche anonime sull’uso di Sito e App al fine di individuare le pagine preferite dagli Utenti e fornire dunque contenuti sempre più adeguati e per controllarne il corretto funzionamento. Su richiesta dell’Autorità, i dati potrebbero essere utilizzati per l’accertamento di responsabilità in caso di ipotetici reati informatici ai danni di Sito e App o dei suoi Utenti.
                    <br />
                </i>

                <b>Informazioni su Cookies, Motori di Ricerca e Dati sulla Posizione  </b>
                <i>I cookies hanno lo scopo di velocizzare l'analisi del traffico su Internet, facilitare agli Utenti l'accesso ai servizi offerti dal Sito e App e fornire pubblicità utile e pertinente ai visitatori. Con l'utilizzo dei cookies non vengono trasmessi né acquisiti dati personali e non vengono utilizzati sistemi di tracciamento degli Utenti. Qualora non desideri che le informazioni da lui fornite vengano raccolte attraverso l’uso di cookie, l’Utente può attuare una semplice procedura presente nel proprio browser che permette di rifiutare la funzione dei cookies.
                    <br />
                    Le informazioni relative alle proposte immobiliari inserite nel Sito e App saranno visibili nelle ricerche effettuate nel motore di ricerca interno e potrebbero essere messe a disposizione di motori di ricerca terzi in quanto Sito e App consentono l'indicizzazione dei propri contenuti da parte di motori terzi.
                    <br />
                    Nel caso in cui la pagina relativa all'annuncio sia già stata rimossa da Sito e App, è possibile che la copia cache permanga tra i risultati di ricerca per qualche giorno. I risultati della ricerca non sono gestiti da Sito e App, ma l'utente può segnalare la rimozione della pagina e sollecitare l'aggiornamento della copia cache direttamente al motore di ricerca stesso.
                    <br />
                    Nell’utilizzo di Sito e App con la funzione di rilevamento della posizione attiva, Sito e App potrebbero raccogliere ed elaborare informazioni sulla posizione attuale dell’Utente. Questi dati vengono trattati in forma anonima, in un formato che non consente di identificare personalmente l'Utente, e utilizzati al solo fine di facilitare la fruizione di alcune funzioni di Sito e App basate sulla posizione. I servizi di localizzazione possono essere attivati o disattivati dall'Utente in qualunque momento accedendo alle impostazioni del proprio dispositivo.
                    <br />
                    Per ulteriori informazioni, si prega di prendere visione della pagina Uso dei Cookie.
                </i>
                <b>Diritti dell'Interessato  </b>
                <i>
                    In particolare, nell’ipotesi della lettera A (I) il mancato conferimento dei dati necessari alla registrazione dell’Utente, determinerà l’impossibilità di erogare anche i servizi di cui alle successive lettere B, C e D.
                    <ul>
                        <li>1) la conferma dell’esistenza o meno di dati personali che lo riguardano, anche se non ancora registrati, in forma concisa, trasparente, intelligibile e facilmente accessibile, con un linguaggio semplice e chiaro;</li>
                        <li>2) l'indicazione:
                            <ul>
                                <li>a. dell’origine dei dati personali;</li>
                                <li>b. delle finalità e modalità di trattamento;</li>
                                <li>c. dei legittimi interessi perseguiti dal Titolare o da terzi;</li>
                                <li>d. degli eventuali destinatari o le eventuali categorie di destinatari dei dati personali;</li>
                                <li>e. dell’eventuale intenzione del titolare di trasferire dati personali a un paese terzo o a un'organizzazione internazionale;</li>
                                <li>f. del periodo di conservazione dei dati personali;</li>
                                <li>g. della logica applicata, nonché l'importanza e le conseguenze previste di tale trattamento per l'interessato, in caso di trattamento effettuato con l’ausilio di strumenti elettronici nell’ambito di un processo automatico di raccolta e/o profilazione;</li>
                                <li>h. degli estremi identificativi del Titolare, dei Responsabili, dell’eventuale Rappresentante designato e del Responsabile della protezione dei Dati (c.d. DPO);</li>
                                <li>i. dei soggetti e delle categorie di soggetti ai quali i dati personali possono essere comunicati o che possono venirne a conoscenza in qualità di rappresentante designato nel territorio dello Stato, di responsabili o incaricati;</li>
                            </ul>
                        </li>
                        <li>3) la possibilità di proporre un reclamo ad un’Autorità di controllo;</li>
                        <li>4) l’aggiornamento, la rettificazione ovvero, quando vi ha interesse, l’integrazione dei dati;</li>
                        <li>5) la cancellazione, la trasformazione in forma anonima o il blocco dei dati trattati in violazione della legge, compresi quelli di cui non è necessaria la conservazione in relazione agli scopi per i quali i dati sono stati raccolti o successivamente trattati;</li>
                        <li>6) la limitazione al trattamento;</li>
                        <li>7) la portabilità dei dati personali che lo riguardano ad altro Titolare del trattamento;</li>
                        <li>8) la revoca del trattamento;</li>
                        <li>9) l’attestazione che le operazioni di cui alle lettere a) e b) sono state portate a conoscenza, anche per quanto riguarda il loro contenuto, di coloro ai quali i dati sono stati comunicati o diffusi, eccettuato il caso in cui tale adempimento si rivela impossibile o comporta un impiego di mezzi manifestatamente sproporzionato rispetto al diritto tutelato;</li>
                        <li>10) l’opposizione, in tutto o in parte, per motivi legittimi, al trattamento dei dati personali che lo riguardano, ancorché pertinenti allo scopo della raccolta.</li>
                    </ul>
                </i>
                
                <b>Modifiche  </b>
                <i>La presente informativa potrebbe essere soggetta a modifiche. Qualora vengano apportate sostanziali modifiche all'utilizzo dei dati relativi all'Utente da parte di airimmobiliare.it, questa avviserà l'Utente pubblicandole con la massima evidenza sulle proprie pagine.</i>






  
  
  
  
  
  
  










                </div>
                
  
            </div>
            
        </section>
        </>
    )
}

export default Privacy;