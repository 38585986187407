import React from "react";
import { Routes, Route, UNSAFE_RouteContext } from "react-router-dom";
import Agente from "./pages/admin/Agente";
import Agenti from "./pages/admin/Agenti";
import Home from "./pages/admin/Home";
import Immobile from "./pages/admin/Immobile";
import Immobili from "./pages/admin/Immobili";
import Utente from "./pages/admin/Utente";
import Utenti from "./pages/admin/Utenti";
import Login from "./pages/Login";
import Logout from "./pages/Logout";
import HomeSito from "./pages/Home";
import Ricerca from "./pages/Ricerca";
import Archivio from "./pages/admin/Archivio";
import Annuncio from "./pages/Annuncio";
import About from "./pages/About";
import Privacy from "./pages/Privacy";
import Termini from "./pages/Termini";




function App() {
  return (
    <Routes>
      <Route path="/" index element={<HomeSito/>}/>
      <Route path="/ricerca" element={<Ricerca/>}/>
      <Route path="/about" element={<About/>}/>
      <Route path="/privacy" element={<Privacy/>}/>
      <Route path="/termini" element={<Termini/>}/>
      <Route path="/ricerca/:codice" element={<Ricerca/>}/>
      <Route path="/annunci/:id" element={<Annuncio/>}/>
      
      <Route path="/login"  element={<Login/>} />
      <Route path="/logout"  element={<Logout/>} />
      <Route path="/admin"  element={<Home/>} />
      <Route path="/admin/agenti"  element={<Agenti/>} />
      <Route path="/admin/archivio"  element={<Archivio/>} />
      <Route path="/admin/agenti/:id"  element={<Agente/>} />
      <Route path="/admin/utenti" element={<Utenti/>} />
      <Route path="/admin/utenti/:id" element={<Utente/>} />
      <Route path="/admin/immobili" element={<Immobili/>} />
      <Route path="/admin/immobili/:id" element={<Immobile/>} />

    </Routes>
  );
}

export default App;
