import { useRef } from "react";
import { useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";



const Logout = () => {
    const [timeLeft, setTimeLeft] = useState(3);
    const intervalRef = useRef();

    useEffect(() => {
        const intervalId = setInterval(() => {
            setTimeLeft((t) => t - 1);
          }, 1000);
          return () => { clearInterval(intervalId);}
    },[])

    useEffect(() => {
        if (timeLeft <= 0) {
            clearInterval(intervalRef.current);
            localStorage.removeItem("agente")
            localStorage.clear()
            window.location.href = '/login'
        }
      }, [timeLeft]);
    

    return(
        <main className="container p-5">
            <div className="card text-center">
                <div className="card-header">
                    LOGOUT
                </div>
                <div className="card-body">
                    Disconnessione in corso...{timeLeft}
                </div>
                
            </div>
        </main>
    )
}

export default Logout