import React from "react";
import { useCallback } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { getRicercaData, searchData } from "../data/sito";

import { BiMap, BiFullscreen } from "react-icons/bi";
import { FaBath } from "react-icons/fa";
import { MdOutlineBathtub, MdOutlineKingBed } from "react-icons/md";

const Ricerca2 = () => {
    const { codice } = useParams();
    const [data, setData] = useState({});
    const [ricerca, setRicerca] = useState({codice: "", tipologia: "", comune: "", provincia: "", zona: ""});
    
    const [loaded, setLoading] = useState(false);
    
    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);
    
    useEffect(()=>{
        console.log(codice)
        if (codice) {
            console.log("opk")
            ricerca.provincia = codice.split("-")[0] !== "n" ? codice.split("-")[0] : ""
            ricerca.comune = codice.split("-")[1] !== "n" ? codice.split("-")[1] : ""
            ricerca.zona = codice.split("-")[2] !== "n" ? codice.split("-")[2] : ""
            const obj = {
                codice: "",
                provincia: ricerca.provincia,
                comune: ricerca.comune,
                zona: ricerca.zona,
                tipologia: "",
            }
            if (!(obj.provincia === "" && obj.comune === "" && obj.zona === "")) {
                searchData(obj).then(result => setData(result)).finally(() => setLoading(false));
            }
        } else {
            getRicercaData().then(e => setData(e)).finally(() => setLoading(true))
        }
    },[])


    const selectProvincia = (e) => {
        ricerca.provincia = e.target.value
        setRicerca(ricerca)
        forceUpdate();
    }
    const selectComune = (e) => {
        ricerca.comune = e.target.value
        setRicerca(ricerca)
        forceUpdate();
    }
    const selectZona = (e) => {
        ricerca.zona = e.target.value
        setRicerca(ricerca)
        forceUpdate();
    }
    const selectTip = (e) => {
        ricerca.tipologia = e.target.value
        setRicerca(ricerca)
        forceUpdate();
    }
    const changeCod = (e) => {
        ricerca.codice = e.target.value
        setRicerca(ricerca)
        forceUpdate();
    }

    const submit = (e) => {
        e.preventDefault();
        setLoading(true)
        const obj = {
            codice: ricerca.codice,
            provincia: ricerca.provincia,
            comune: ricerca.comune,
            zona: ricerca.zona,
            tipologia: ricerca.tipologia,
        }
        if (!(obj.codice === "" && obj.provincia === "" && obj.comune === "" && obj.zona === "" && obj.tipologia === "")) {
            searchData(obj).then(result => setData(result)).finally(() => setLoading(false));
        }
    }

    return(
    <>
        <section className="py-5 text-center hbanner banner-mini">
            <div className="container">
                <header className="d-flex flex-wrap align-items-center justify-content-center justify-content-md-between mb-5">
                    <a href="/" className="d-flex align-items-center col-md-3 mb-2 mb-md-0 text-dark text-decoration-none">
                        <h6><b>AIR IMMOBILIARE</b></h6>
                    </a>

                    <ul className="nav col-12 col-md-auto mb-2 justify-content-center mb-md-0">
                        <li><a href="/" className="nav-link px-2 link-dark">Home</a></li>
                        <li><a href="/ricerca" className="nav-link px-2 link-dark active">Immobili</a></li>
                        <li><a href="/#agenti" className="nav-link px-2 link-dark">Agenti</a></li>
                        <li><a href="/about" className="nav-link px-2 link-dark">Chi siamo</a></li>
                    </ul>

                    <div className="col-md-3 text-end">
                        <a className="btn me-2 grey" href="/login">Accedi</a>
                    </div>
                </header>

                <h1 className="display-6 fw-bold text-white">Ricerca immobile</h1>
                <div className="container text-center ">
                    <form className="col-lg-6 mx-auto" onSubmit={submit}>
                        <div className="row mt-4">
                            <div className="col">
                                <select id="provincia" className="form-select" aria-label="Default select example" onChange={selectProvincia}>
                                    <option value="">Provincia</option>
                                    <option value="7">Ravenna</option>
                                    <option value="3">Forlì - Cesena</option>
                                </select>
                            </div>
                            <div className="col">
                                <select className="form-select" aria-label="Default select example" onChange={selectComune}>
                                    <option value="">Comune</option>
                                    {
                                        ricerca.provincia && data.comuni && data.comuni.map((c,k) => {
                                            if (c.provincia.toString() === ricerca.provincia)
                                                return(
                                                    <option value={c.idComune} key={k}>{c.nome}</option>
                                                )
                                        })
                                    }
                                    
                                    
                                </select>
                            </div>
                            <div className="col">
                                <select className="form-select" aria-label="Default select example" onChange={selectZona}>
                                    <option value="">Zone</option>
                                    {
                                        ricerca.comune && data.zone && data.zone.map((z,k) => {
                                            if (z.comune.toString() === ricerca.comune)
                                                return(
                                                    <option value={z.idZona} key={k}>{z.nome}</option>
                                                )
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                        <div className="row mt-4">
                            <div className="col">
                                <select className="form-select" name="tipologia" aria-label="Default select example" onChange={selectTip}>
                                    <option value="">Tipologia Immobile</option>
                                    {
                                        data.tipologie && data.tipologie.map((t, k) => {
                                            return(<option value={t.idTipologia} key={k}>{t.nome}</option>)
                                        })
                                    }
                                </select>
                            </div>
                            <div className="col">
                                <input className="form-control" type="text" aria-label="Default select example" placeholder="Codice" onChange={changeCod} />
                            </div>
                            <div className="col">
                                <button type="submit"  className="btn grey w-100">Cerca</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>           
        </section>
       

        


        <div className="container mt-5">
            <div className="row row-cols-1 row-cols-md-3 row-cols-lg-4 row-cols-xl-5 g-2 g-lg-3 text-center">
                {
                    data.annunci && data.annunci.map((a,k) => {
                        return(
                            <div className="col" key={k}>
                                <div className="card card-annuncio" onClick={()=> window.location.href = '/annunci/'+a.codice}>
                                    <img src={"https://api.airimmobiliare.it/static/immobili/"+a.idImmobile+"/img/"+a.thumbnail} className="card-img-top" alt="anteprima immobile" />
                                    <div className="card-img-overlay h-100 d-flex justify-content-end">
                                        <p className="card-text price">{a.prezzo.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.")} €</p>
                                    </div>
                                    <div className="card-body">
                                        <h5 className="card-title">{a.tipologia}</h5>
                                        <p className="card-text">Cod: {a.codice}<br/><BiMap className="icons text-verde" />{a.comune} - {a.zona}
                                        <br/><MdOutlineKingBed className="icons"/> {a.letti} <MdOutlineBathtub className="icons"/> {a.bagni} <BiFullscreen className="icons"/> {a.superficie}</p>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>

    </>
    )
}

export default Ricerca2;