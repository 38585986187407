import React, { useEffect, useState } from "react"
import AgentCard from "../../components/cards/Agente"
import { getAgente, updateAgente } from "../../data/agente";
import { useParams } from "react-router-dom";


const agente = JSON.parse(localStorage.getItem("agente"));


const Agente = () => {
    const { id } = useParams();
    const [a, setAgente] = useState(null);
    const [isLoading, setLoading] = useState(true);
    
    useEffect(()=> {
        getAgente(id).then((result)=> setAgente(result))
        setLoading(false);
    },[])

    const edit = (e) => {
        const element = e.target;
        if (element.type === "checkbox") {
            a[element.name] = element.checked;
        } else a[element.name] = element.value;
    }
    
    const submit = async (e) => {
        e.preventDefault();
       // console.log(a)
        const update = await updateAgente(a);
        console.log(update)
       /* if (!update.ok) {
            const element = document.getElementsByName(update.error.field)[0]
            console.log(element)
            element.className +=" is-invalid"
            const root = element.parentElement
            const div = document.createElement("div");
            div.className = "invalid-feedback";
            div.innerHTML  = update.error.message;
            root.appendChild(div);
        }*/
    }

    return(
        <>
            <header>
                <nav className="navbar navbar-expand-lg navbar-light bg-light">
                    <div className="container-fluid">
                        <a className="navbar-brand" href="/admin">Home Finder</a>
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        
                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                            <li className="nav-item">
                                <a className="nav-link active" aria-current="page" href="/admin">Home</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link " aria-current="page" href="/admin/utenti">Utenti</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link " aria-current="page" href="/admin/immobili">Immobili</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link " aria-current="page" href="/admin/agenti">Agenti</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link " aria-current="page" href="/admin/archivio">Archivio</a>
                            </li>                 
                            </ul>
                            
                            <div className="dropdown">
                                <label className="dropdown-toggle" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                {agente.nome}
                                </label>
                                <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown">
                                <li><a className="dropdown-item" href={`/admin/agenti/${agente.idAgente}`}>Account</a></li>
                                <li><hr className="dropdown-divider"/></li>
                                <li><a className="dropdown-item" href="/logout">Logout</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </nav>
            </header>
            <div className="container">
                <div className="row mt-5">
                    <div className="col-sm-12 col-md-6 col-lg-4">
                        {<AgentCard agente={a}/>}
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-8 mt-3">
                        <form className="border p-4" onSubmit={submit}>
                        {
                            !isLoading && a ? 
                            <div>
                                <div className="input-group mb-3">
                                    <input type="text" name="nome" defaultValue={a.nome} className="form-control" placeholder="Nome" aria-label="Nome" aria-describedby="basic-addon1" onChange={edit}/>
                                    <input type="text" value={a.codice} className="form-control" placeholder="Codice" aria-label="Codice" aria-describedby="basic-addon1" disabled/>
                                </div>
                            
                            
                                <div className="input-group mb-3">
                                    <input type="text" name="telefono" defaultValue={a.telefono} className="form-control" placeholder="Telefono" aria-label="Telefono" aria-describedby="basic-addon1" onChange={edit}/>
                                    <input type="text" name="telefono2" defaultValue={a.telefono2} className="form-control" placeholder="Telefono 2" aria-label="Telefono 2" aria-describedby="basic-addon1" onChange={edit}/>
                                </div>
                            
                            
                                <div className="input-group mb-3">
                                    <input type="email" name="email" defaultValue={a.email} className="form-control" placeholder="email" aria-label="email" aria-describedby="basic-addon1" onChange={edit}/>
                                    <input type="email" name="pec" defaultValue={a.pec} className="form-control" placeholder="pec" aria-label="pec" aria-describedby="basic-addon1" onChange={edit}/>
                                    
                                </div>

                                <div className="input-group mb-3">
                                    <input type="text" name="piva" defaultValue={a.piva} className="form-control" placeholder="piva" aria-label="piva" aria-describedby="basic-addon1" onChange={edit}/>
                                    <input type="text" name="rea" defaultValue={a.rea} className="form-control" placeholder="rea" aria-label="rea" aria-describedby="basic-addon1" onChange={edit}/>
                                </div>

                                <div className="mb-3">
                                    <label className="form-label">Iscrizione</label>
                                    <input type="date" name="iscrizione" className="form-control" defaultValue={a.iscrizione ? new Date(a.iscrizione).toLocaleDateString('en-CA') : null} onChange={edit}/>
                                </div>
                                <div className="mb-3 form-check">
                                    <input type="checkbox" name="consulente" className="form-check-input" id="exampleCheck1" defaultChecked={a.consulente} onChange={edit}/>
                                    <label className="form-check-label">Consulente</label>
                                </div>
                                <div className="mb-3 form-check">
                                    <input type="checkbox" name="visibile" className="form-check-input" id="exampleCheck2" defaultChecked={a.visibile} onChange={edit}/>
                                    <label className="form-check-label">Visibile</label>
                                </div>
                                <div className="mb-2">
                                    <button type="submit" className="btn btn-primary w-100">Aggiorna</button>
                                </div>
                            </div>
                            : null
                        }
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Agente