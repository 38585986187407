import LOGO from "../img/LOGO.png";
import { MdCall  } from "react-icons/md";
import { AiOutlineWhatsApp } from "react-icons/ai";

const cardAgente = ({agente, provincia}) => {
    if(agente) {
        return(
            <div className="card p-3 card-agente">
                <img src={agente.img} className="card-img-top rounded-circle img-agente p-4" alt="profilo agente" />
                <div className="card-body">
                    <h5 className="card-title green">{agente.nome}</h5>
                    <p className="card-text">{agente.consulente ? "Consulente" : "Agente immobiliare"}</p>    
                    <p className="card-text text-center">{agente.email}</p>    
                    <div className="container">
                        <div className="row ">
                            <div className="col">
                                <a className="btn w-100" href={"tel:"+agente.telefono}><MdCall /></a>
                            </div>
                            <div className="col">
                                <a className="btn w-100" href={"https://wa.me/"+agente.telefono} target="_blank" rel="noreferrer"><AiOutlineWhatsApp/></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    } else {
        return(
            <div className="card p-3">
                <img src={LOGO} className="card-img-top rounded-circle p-4" alt="..." />
                <div className="card-body text-center">
                    <h5 className="card-title green">AIR IMMOBILIARE { provincia==7 ? "Ravenna" : "Forlì" }</h5>
                    <p className="card-text text-center">Chiama al numero: {provincia==7 ? "0544 771 781" : "0543 423 388"}</p>    
                    <div className="container">
                        <div className="row ">
                            <div className="col">
                                
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
        
    }

    
}

export default cardAgente;