import React from 'react';
import { useState, useEffect, useRef } from 'react';
import QRCode from 'react-qr-code';
import { getAgente, getWhatsapp, resetWhatsapp } from '../data/agente';

const Whatsapp = (props) => {
    const [data, setData] = useState(null);
    const [agente, setAgente] = useState(null);
    const [isLoading, setLoading] = useState(false);
    //const forceUpdate = useCallback(() => updateState({}), []);


    useEffect(()=> {
        if (props.agente) getWhatsapp(props.agente).then((result) => setData(result))
        .then(()=> setLoading(false))
        //.then(() => clearTimer(getDeadTime()));
        //if (props.id) getDataRichiesta(props.id).then((result) => setData(result)).finally(()=> setLoading(false));
        //else setLoading(false)
    },[]);

    const updateData = async () => {
        setLoading(true);
        const agente = await getAgente(props.agente.idAgente);
        setAgente(agente);
        setLoading(false);
    }

    const reset = async () => {
        setLoading(true);
        const r = await resetWhatsapp(props.agente);
        const agente = await getAgente(props.agente.idAgente);
        setAgente(agente);
    }

    console.log(agente)
    return(
    <>
        <h6>WHATSAPP-BOT</h6>
        {!isLoading && agente && agente.whatsapp == "1" || props.agente.whatsapp == "1" ? "Stato : online" : "Stato : offline"}<br/>
        {!isLoading && agente && agente.whatsapp == "3" || props.agente.whatsapp == "3" ? "collegamento in corso..." : null }<br/>
        
        {isLoading ? 
            <div className="spinner-border text-success" role="status">
                <span className="visually-hidden">Loading...</span>
            </div>
            : null
        }

        { !isLoading && agente && agente.whatsapp.length > 1  ?
            <QRCode
                title="AIRIMMOBILIARE"
                value={agente.whatsapp}
                bgColor={'#FFFFFF'}
                fgColor={'#47b847'}
                size={256}
            />
            : null
        }

        
        <div className='container mt-3'>
            <div className='row'>
                {

                    <div className='col'>
                        <button className='btn btn-success w-100' onClick={updateData}>Aggiorna</button>
                    </div>
                }
                
                {  

                    <div className='col'>
                        <button className='btn btn-danger w-100' onClick={reset}>Resetta</button>
                    </div>
                }
            </div>
        </div>

    </>
    );
}

export default Whatsapp;