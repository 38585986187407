const proxy = "https://api.airimmobiliare.it/api"

export async function checkEmail(email) {
    const response = await fetch(`${proxy}/agenti/find`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({key: "email", value: email})
    });
    return response.json();
}

export async function updateProfileImg(id, img) {
    const response = await fetch(`${proxy}/agenti/${id}`, {
        method: 'PATCH',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({key: "img", value: img})
    });
    return response.json();
}



export async function getIp() {
    const response = await fetch("https://geolocation-db.com/json/",{
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': 'http://localhost:3000',
          'Access-Control-Allow-Credentials': 'true'
        }
    });
    return response.json();
}

export async function addSession(session) {
    //console.log(session)
    const response = await fetch(`${proxy}/sessioni`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(session)
    });
    return response.json();
}