const proxy = "https://api.airimmobiliare.it/api"
//const proxy = "/api"

export async function getAgenti() {
    const response = await fetch(`${proxy}/agenti`);
    return response.json();
}

export async function getAgente(id) {
    const response = await fetch(`${proxy}/agenti/${id}`);
    return response.json();
}

export async function updateAgente(agente) {
    //console.log(`${proxy}/agenti/${agente.idAgente}`)
    const response = await fetch(`${proxy}/agenti/${agente.idAgente}`, {
        method: 'PUT',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(agente)
    });
    return response.json();
}

export async function getWhatsapp(agente) {
    const response = await fetch(`${proxy}/whatsapp`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({agente: agente})
    });
    //return response.json();
}

export async function resetWhatsapp(agente) {
    const response = await fetch(`${proxy}/whatsapp-reset`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({agente: agente})
    });
    //return response.json();
}

export async function sendWhatsapp(data) {
    console.log(data)
    const response = await fetch(`${proxy}/whatsapp-sm`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    });
    //return response.json();
}