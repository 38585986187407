import { GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";
import jwtDecode from "jwt-decode";
import { useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { checkEmail, updateProfileImg, getIp, addSession } from "../data/login";
const clientId = "940287538887-s3jc2gkairol62dnd8upffb8cg91ea09.apps.googleusercontent.com";


const Login = () => {
    const [error, setError] = useState('');

    const onSuccess = async token_success => {
        const data = await jwtDecode(token_success.credential)
        // verifica agente con la mail
        console.log("a")
        const db = await checkEmail(data.email);
        console.log(db)
        if(db.length > 0) {
            const user = db[0];
            // aggiorna la foto profilo
            
            //const u = await updateProfileImg(user.idAgente, data.picture);
            //const u = await 

            // crea la sessione
            //setError(JSON.stringify(data)+JSON.stringify(user));
            //console.log("c")
            //const ip = await getIp();
            //console.log(ip)
            const sessione = await addSession({
                idSessione: null,
                agente: user.idAgente,
                token: '0001',
                ua: window.navigator.userAgent,
                ip: 'ip.IPv4'
            });
            //console.log(u)
            localStorage.setItem("agente", JSON.stringify(user));
            window.location.href = 'admin';
        } else setError('Utente non registrato');
    }

    const onError = () => {
        setError('Login fallito!');
    }



    return(
        <main className="container p-5">
            <div className="card text-center">
                <div className="card-header">
                    LOGIN
                </div>
                <div className="card-body">
                    <GoogleOAuthProvider clientId={clientId}>
                    <GoogleLogin
                        onSuccess={onSuccess}
                        onError={onError}
                        useOneTap
                    />
                    </GoogleOAuthProvider>

                    {
                        error ? 
                            <div class="alert alert-danger mt-3" role="alert">
                                {error}
                            </div>
                        : null
                    }
                </div>
                
            </div>
        </main>
    )
}

export default Login