const proxy = "https://api.airimmobiliare.it/api"

export async function previewImm(id) {
    const response = await fetch(`${proxy}/immobili/preview/${id}`, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
    });
    return response.json();
}

export async function getProperties(utente) {
  const response = await fetch(`${proxy}/immobili`, {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
  });
  return response.json();
}

export async function getPropertiesArchive(utente) {
  const response = await fetch(`${proxy}/archivio`, {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
  });
  return response.json();
}

export async function getData(id) {
  const response = await fetch(`${proxy}/admin/immobili/${id}`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
  });
  return response.json();
}

export async function getIndirizzo() {
  const response = await fetch(`${proxy}/api/indirizzo`);
  return response.json();
}

export async function getFabbricato() {
  const response = await fetch(`${proxy}/api/fabbricato`);
  return response.json();
}

export async function getCondizioni() {
  const response = await fetch(`${proxy}/api/condizioni`);
  return response.json();
}

export async function getImmobili() {
  const response = await fetch(`${proxy}/api/immobil`);
  return response.json();
}

export async function getAccessori() {
  const response = await fetch(`${proxy}/api/accessori`);
  return response.json();
}

export async function getRiscaldamento() {
  const response = await fetch(`${proxy}/api/riscaldamento`);
  return response.json();
}

export async function getClassi(certificazione) {
  const response = await fetch(`${proxy}/api/classi/${certificazione}`);
  return response.json();
}

export async function getImmobile(id) {
  const response = await fetch(`${proxy}/api/immobili/${id}`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: ""
  });
  return response.json();
}

export async function addNewProperties(obj) {
  const response = await fetch(`${proxy}/api/immobile/add`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(obj)
  });
  return response.json();
}

export async function getProprietario(id) {
  const response = await fetch(`${proxy}/api/utenti/${id}`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: ""
  });
  return response.json();
}

export async function addProprietario(immobile, agente, utente, nome) {
  const response = await fetch(`${proxy}/proprietari`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({agente: agente, immobile: immobile, utente: {idUtente: utente, nome: nome}})
  });
  return response.json();
}

export async function updateImmobile(agente, immobile) {
  console.log(immobile)
  const response = await fetch(`${proxy}/immobili/${immobile.idImmobile}`, {
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({agente: agente, immobile: immobile})
  });
  return response.json();
}

export async function updateThumbnail(id, thumbnail) {
  const response = await fetch(`${proxy}/immobili/${id}`, {
      method: 'PATCH',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({key: "thumbnail", value: thumbnail})
  });
  return response.json();
}

export async function uploadDoc(obj) {
  const response = await fetch(`${proxy}/immobili/doc`, {
      method: 'POST',
      body: obj
  });
  return response.json();
}

export async function getDocuments(id) {
  const response = await fetch(`${proxy}/api/documenti/${id}`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: ""
  });
  return response.json();
}

export async function uploadImg(obj) {
  console.log(obj)
  const response = await fetch(`${proxy}/immobili/img`, {
      method: 'POST',
      body: obj
  });
  return response.json();
}

export async function getImg(id,foto) {
  const response = await fetch(`${proxy}/api/immobile/${id}/foto`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({foto: foto})
  });
  return response;
}

export async function getImgSrc(obj) {
  const response = await fetch(`${proxy}/api/immagini/get`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(obj)
  });
  const blob = await response.blob();
  //const url = URL.createObjectURL(blob);
  return blob;
}

export async function swapImgIndex(obj) {
  const response = await fetch(`${proxy}/immobili/img/swap`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(obj)
  });
  return response.json();
}

export async function deleteImg(obj) {
  const response = await fetch(`${proxy}/immobili/img/del`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(obj)
  });
  return response.json();
}

export async function downloadDoc(obj) {
  const response = await fetch(`${proxy}/immobili/doc/download`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(obj)
  });
  const blob = await response.blob();
  //const url = URL.createObjectURL(blob);
  return blob;
}

export async function deleteDoc(obj) {
  const response = await fetch(`${proxy}/immobili/doc/del`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(obj)
  });
  return response.json();
}

export async function getDiario(id) {
  const response = await fetch(`${proxy}/api/diario/1/${id}`, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: ""
  });
  return response.json();
}

export async function archiviaImm(id) {
  const response = await fetch(`${proxy}/immobili/archive/${id}`, {
    method: 'PATCH',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({immobile: id})
  });
  return response.json();
}

export async function dearchiviaImm(id) {
  const response = await fetch(`${proxy}/immobili/dearchive/${id}`, {
    method: 'PATCH',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({immobile: id})
});
return response.json();
}