//import { proxy } from "../config";
const proxy = "https://api.airimmobiliare.it/api"

const headers = {
  'Accept': 'application/json',
  'Content-Type': 'application/json'
};

export async function searchProperties(obj) {
  const response = await fetch(`${proxy}/immobili/search`, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(obj)
  });
  return response.json();
}

export async function searchUsers(obj) {
  const response = await fetch(`${proxy}/utenti/search`, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(obj)
  });
  return response.json();
}