const CardUtenti = (props) => {
    const utente = props.utente;
    return(
        <div className="card">
            <div className="card-body">
                <h5 className="card-title">{utente.nome}</h5>
                {
                    utente.lavoro || utente.azienda ? <h6 className="card-subtitle mb-2 text-muted">{utente.lavoro} ({utente.azienda})</h6> : null
                }
                <p className="card-text">{utente.email} <br/>{utente.telefono}</p>
            </div>
            <div className="card-footer">
                <a className="btn btn-primary w-100" href={`/admin/utenti/${utente.idUtente}`}>Vai al profilo</a>
            </div>
        </div>
    )
}

export default CardUtenti